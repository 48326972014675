import {
  GET_PACKAGE_INDEX,
  GET_USER_PACKAGE_INDEX,
  BOOKING_PACKAGE,
  CHECK_CREDIT,
  USE_CREDIT,
  GET_PACKAGE_DETAILS,
  CHECK_COUPON,
  GET_USABLE_USER_PACKAGE,
  CHECK_COUPON_CLEAR,
} from './actions';

export const getPackageIndexAction = (payload) => ({
  type: GET_PACKAGE_INDEX,
  payload,
});
export const getPackageDetailsAction = (payload) => ({
  type: GET_PACKAGE_DETAILS,
  payload,
});

export const getUserPackageIndexAction = (payload) => ({
  type: GET_USER_PACKAGE_INDEX,
  payload,
});

export const bookingPackageAction = (payload) => ({
  type: BOOKING_PACKAGE,
  payload
});

export const checkCreditAction = (payload) => ({
  type: CHECK_CREDIT,
  payload
});

export const useCreditAction = (payload) => ({
  type: USE_CREDIT,
  payload
});

export const checkCouponAction = (payload) => ({
  type: CHECK_COUPON,
  payload
});

export const checkCouponClearAction = () => ({
  type: CHECK_COUPON_CLEAR
});

export const getUsableUserPackageAction = (payload) => ({
  type: GET_USABLE_USER_PACKAGE,
  payload,
});